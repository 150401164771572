
/*   -=========== Structure ===========-   */

// Base

body {
  &:not(.is-ready) {
    overflow-y: scroll;

    #structure {
      height: 100vh;
      overflow: hidden;
    }

    .footer {
      display: none;
    }
  }
}

#structure {
  margin: auto;
  position: relative;
}

.footer {
  position: relative;
  padding-bottom: $bottom-menu-height;

  @media $not-small-handheld {
    line-height: 115px;
  }

  @media $small-handheld {
    padding-top: 30px;
    padding-bottom: $bottom-menu-height + 30px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;

    @media $small-handheld {
      flex-wrap: wrap;
    }

    @media $small-handheld {
      justify-content: center;
    }
  }
}


// Header

.header {
  height: $header-height; width: 100%;
  position: absolute;
  top: 0; left: 0;
  padding-top: 50px;
  z-index: 100;
  background: linear-gradient(to bottom, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 100%);

  @media $small-mobile {
    height: $mobile-header-height;
    padding-top: 16px;
  }

  .wrapper {
    position: relative;
    height: 100%;
  }
}

.header--marathon-link {
  color: $white;
  text-decoration: none;
  font-size: 1.3rem;
  padding-left: 35px;
  line-height: 29px;
  position: absolute;
  left: 0; top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .75s ease-in-out;
  transition-delay: .5s;

  @media only screen and (max-height: 860px) {
    margin-top: -50px;
  }

  &:before, &:after {
    content: '';
    display: block;
    width: 21px; height: 2px;
    background: $white;
    position: absolute;
    left: 0;
  }

  &:before {
    bottom: 50%;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    margin-bottom: -1px;
  }

  &:after {
    top: 50%;
    transform: rotate(45deg);
    transform-origin: left top;
    margin-top: -1px;
  }

  body.is-ready & {
    opacity: 1;
  }
}

.header--bmw-logo {
  display: block;
  width: width('i/bmw-logo.png'); height: height('i/bmw-logo.png');
  background: url(/i/bmw-logo.png) no-repeat 50% / contain;
  position: absolute;
  top: 50px; right: 67px;
  font-size: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;

  @media (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(/i/bmw-logo@2x.png);
  }

  @media $small-mobile {
    width: 69px; height: 51px;
    right: 30px; top: 16px;
  }

  &:after {
    content: '';
    display: block;
    width: 100%; height: height('i/bmw-slogan.png');
    background: url(/i/bmw-slogan.png) no-repeat 50% / contain;
    position: absolute;
    left: 0; top: 100%;
    margin-top: 6px;
    /* opacity: 0;
    transform: translateY(-50%);
    transition: all .75s ease-in-out;
    transition-delay: .5s; */

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(/i/bmw-slogan@2x.png);
    }

    @media $small-mobile {
      display: none;
      /* height: 21px;
      margin-top: -1px; */
    }
  }

  body.is-ready & {
    opacity: 1;

    /* &:after {
      transform: translateY(0%);
      opacity: 1;
    } */
  }
}


// Footer

.footer--logo-group {
  @media $small-handheld {
    margin-bottom: 30px;
    text-align: center;

    @media $not-small-mobile {
      line-height: 100px;
      width: 100%;
    }
  }

  @media $small-mobile {
    display: flex;
    width: 36%;
    white-space: nowrap;
  }
}

.footer--logo {
  display: inline-block;
  vertical-align: middle;
  width: width('i/marathon-logo.svg'); height: 100%;
  background: url(/i/marathon-logo.svg) no-repeat 50%;
  background-size: size('i/marathon-logo.svg');
  font-size: 0;

  @media $mobile {
    background-size: contain;
    height: 34px;
  }

  @media $small-mobile {
    flex: 1;
    margin-left: -8%;
  }
}

.footer--ripcurl-logo {
  display: inline-block;
  vertical-align: middle;
  width: width('i/ripcurl-logo.svg'); height: 100%;
  background: url(/i/ripcurl-logo.svg) no-repeat 50%;
  background-size: size('i/ripcurl-logo.svg');
  font-size: 0;
  position: relative;
  margin-left: 14px;

  &:before {
    content: '';
    display: block;
    width: width('i/logo-line.svg'); height: 100%;
    background: url(/i/logo-line.svg) no-repeat 50%;
    background-size: size('i/logo-line.svg');
    position: absolute;
    right: 100%; top: 0;
    margin-right: 3px;

    @media $mobile {
      background-size: auto 90%;
      height: 34px;
    }
  }

  @media $mobile {
    background-size: contain;
    height: 34px;
  }

  @media $small-mobile {
    flex: 1;
  }
}

.footer--socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  padding-top: 5px;
  /* margin-right: 20%; */

  @media $desktop {
    flex-basis: 200px;
  }

  @media $small-handheld {
    margin-top: 5px;
  }

  @media $small-mobile {
    margin-left: 50px;
    margin-right: 50px;
  }

  .item {
    display: block;
    height: 26px;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      position: absolute;
      left: 0; top: 0;
      opacity: 0;
      transition: opacity .25s ease;
    }
  }

  .item:hover:after {
    opacity: 1;
  }

  .item + .item {
    margin-left: 10px;
  }

  $socials-list = fb, vk, inst, utb;

  for $social in $socials-list {
    .item_{$social} {
      background: url('/i/sm-' + $social + '-white.png') no-repeat 50%;
      width: width('i/sm-' + $social + '-blue.png');

      &:after {
        background: url('/i/sm-' + $social + '-blue.png') no-repeat 50%;
      }
    }
  }
}

.footer--copyright {
  padding-top: 20px;
  font-size: 1rem;

  &, a {
    color: #626262;
  }

  .icon {
    vertical-align: middle;
    margin-left: 15px;
    margin-top: -5px;
    width: 300px; height: 29px;
    background: url(/i/shogo-logo.svg) no-repeat 50% / contain;
  }

  @media $small-mobile {
    text-align: center;
    line-height: 1.5;

    .icon {
      display: block;
      margin: 15px auto 0;
    }
  }
}


// Loading

.page-loading-overlay {
  width: 100%; height: 100%;
  background: $black url(/i/loader-bg.jpg) no-repeat 50% / cover;
  position: fixed;
  left: 0; top: 0;
  z-index: 1000;

  @media $small-mobile {
    background-image: url(/i/loader-bg-m.jpg);
  }

  &:after {
    content: '';
    display: block;
    width: 180px; height: 180px;
    background: url(/i/loader-logo.svg) no-repeat 50% / contain;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }
}

.page-loading-overlay--progressbar {
  position: absolute;
  left: 0; top: 0;
  height: 11px;
  background: $blue;
  transition: width .25s ease;
}

.page-loading-overlay--counter {
  position: absolute;
  left: 50%; top: 50%;
  transform: translateX(-50%);
  margin-top: 110px;
  z-index: 100;
  font-size: 4.1rem;
  font-weight: bold;
}


// Rotation warning

.rotate-device-warning {
  display: none;
  background: $black url(/i/rotate-device.png) no-repeat 50% 40%;
  width: 100%; height: 100%;
  position: fixed;
  left: 0; top: 0;
  z-index: 10000;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: $blue;
  text-align: center;

  span {
    position: absolute;
    left: 50%; bottom: 20px;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  @media only screen and (max-device-width: 700px) and (min-device-width: 320px) and (orientation: landscape) {
    display: block;
  }
}

@media only screen and (max-device-width: 700px) and (min-device-width: 320px) and (orientation: landscape) {
  body {
    height: 100vh;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 200%;
      background: $black;
      position: fixed;
      left: 0; top: 0;
    }
  }

  .bottom-menu {
    display: none;
  }
}
