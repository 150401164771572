
/*   -=========== Homepage ===========-   */

mobile-bg($xs, $sd, $hd) {
  @media $small-mobile {
    @media (-webkit-max-device-pixel-ratio: 1) {
      background: url($xs) no-repeat 50% / cover;
    }

    @media (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      background: url($sd) no-repeat 50% / cover;
    }

    @media (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      background: url($hd) no-repeat 50% / cover;
    }
  }
}

.slide_1 {
  /* height: 777px; */
  /* height: 'calc(100vh - %s)' % $bottom-menu-height; */
  height: 100vh;
  background: url(/i/slide-1.jpg) no-repeat 50% / cover;
  overflow: hidden;

  mobile-bg('/i/slide-1-m.jpg', '/i/slide-1-m@2x.jpg', '/i/slide-1-m-hd@2x.jpg');

  @media $small-mobile {
    height: 'calc(100vh - %s)' % $bottom-menu-height;
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
  }

  .glitch_1 {
    background: url(/i/slide-1-glitch-1.png) no-repeat 50% / cover;
    top: 0;
    transform: translateY(3%);
    transition: all 1s ease-in-out;
  }

  .glitch_2 {
    background: url(/i/slide-1-glitch-2.png) no-repeat 50% / cover;
    bottom: 0;
    transform: translateY(-1%);
    transition: all 1.5s .5s ease-in-out;
  }

  .slide--caption {
    font-size: 7.3rem;
    font-weight: bold;
    line-height: 1.05;
    letter-spacing: -.03em;
    white-space: pre;
    text-transform: uppercase;
    position: absolute;
    left: 0; top: 14.5%;
    opacity: 0;
    transition: all 1s 1s ease-in-out;

    @media $landscape-tablet {
      font-size: 5rem;
      top: 18%;
    }

    @media $portrait-tablet {
      font-size: 4.5rem;
    }

    @media $small-mobile {
      font-size: 2.3rem;
      top: 18%;
    }

    span {
      display: inline-block;
      vertical-align: top;
      opacity: 0;
      transform: translateY(-5%);
      transition: all .5s ease-in-out;

      &.is-animated {
        opacity: 1;
        transform: translateY(0%);
      }
    }
  }

  .slide--text {
    font-size: 1.4rem;
    position: absolute;
    left: 0; top: 55%;
    opacity: 0;
    transform: translateY(50%);
    transition: all 1s 1.5s ease-in-out;

    @media $tablet {
      top: 50%;
    }

    @media $small-mobile {
      top: auto; bottom: 5%;
    }
  }

  .slide--text-caption {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1em;
  }

  body.is-ready & {
    .glitch {
      opacity: 1;
    }

    .glitch_1 {
      transform: translateY(0%);
    }

    .glitch_2 {
      transform: translateY(0%);
    }

    .slide--caption {
      opacity: 1;
    }

    .slide--text {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

.slide_group-index {
  height: auto;

  .wrapper {
    padding-top: 5%;
  }

  @media $small-mobile {
    height: auto;
    padding-bottom: 25%;

    .wrapper {
      padding-top: 25%;
    }
  }

  .text-on-drugs {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    margin-bottom: 5%;

    /* html.svg & { */
      font-size: 0;

      &:before {
        content: '';
        display: block;
        padding-bottom: 18%;
      }
    /* } */

    /* html.no-svg & {
      font-size: 24.3rem;
      color: $red;

      @media $landscape-tablet {
        font-size: 14rem;
      }

      @media $portrait-tablet {
        font-size: 10rem;
      }

      @media $mobile {
        font-size: 4rem;
      }
    } */
  }

  .text-on-drugs_exterior {
    background-image: url(/i/ext.png);

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(/i/ext@2x.png);
    }
  }

  .text-on-drugs_interior {
    background-image: url(/i/int.png);

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(/i/int@2x.png);
    }
  }

  .text-on-drugs_lifestyle {
    background-image: url(/i/life.png);

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(/i/life@2x.png);
    }
  }

  .section-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5%;
    margin-top: 3%;

    a {
      flex-basis: 15%;
      text-decoration: none;
      font-size: 1.4rem;
      text-align: center;
      position: relative;

      &:before {
        content: '';
        display: block;
        padding-bottom: 80%;

        @media $small-mobile {
          padding-bottom: 110%;
        }
      }
    }

    @media $small-handheld {
      flex-wrap: wrap;
      justify-content: space-around;

      a {
        box-sizing: border-box;
        flex-basis: 35%;
        margin-top: 25px;
        padding: 0 10px;
        font-size: 1.2rem;
      }
    }

    .item--caption {
      display: block;
      width: 100%;
      position: absolute;
      left: 0; top: calc(100% - 3em);
    }

    .item--icon {
      display: block;
      width: 100%;
      position: absolute;
      left: 50%; top: 0;
      transform: translateX(-50%);

      &:before {
        content: '';
        display: block;
        padding-bottom: 50%;
      }

      @media $small-handheld {
        max-width: 200px;
      }
    }

    .item_wheels .item--icon {
      background: url(/i/icon-1.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-1.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-1@2x.png);
        }
      }
    }

    .item_trunk .item--icon {
      background: url(/i/icon-2.svg) no-repeat 50% / auto 80%;
      width: 120%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-2.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-2@2x.png);
        }
      }

      &:before {
        padding-bottom: 40%;
      }
    }

    .item_camera .item--icon {
      background: url(/i/icon-3.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-3.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-3@2x.png);
        }
      }
    }

    .item_projectors .item--icon {
      background: url(/i/icon-4.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-4.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-4@2x.png);
        }
      }
    }

    .item_mat .item--icon {
      background: url(/i/icon-5.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-5.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-5@2x.png);
        }
      }
    }

    .item_net .item--icon {
      background: url(/i/icon-6.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-6.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-6@2x.png);
        }
      }
    }

    .item_dvr .item--icon {
      background: url(/i/icon-7.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-7.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-7@2x.png);
        }
      }
    }

    .item_heater .item--icon {
      background: url(/i/icon-8.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-8.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-8@2x.png);
        }
      }
    }

    .item_backpack .item--icon {
      background: url(/i/icon-9.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-9.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-9@2x.png);
        }
      }
    }

    .item_flask .item--icon {
      background: url(/i/icon-10.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-10.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-10@2x.png);
        }
      }
    }

    .item_bag .item--icon {
      background: url(/i/icon-11.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-11.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-11@2x.png);
        }
      }
    }

    .item_glasses .item--icon {
      background: url(/i/icon-12.svg) no-repeat 50% / auto 80%;

      .no-svg & {
        @media (-webkit-min-device-pixel-ratio: 1) {
          background-image: url(/i/icon-12.png);
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
          background-image: url(/i/icon-12@2x.png);
        }
      }
    }
  }
}

.slide_2 {
  .box-with-text {
    &:before {
      content: 'Экстерьер';
    }
  }
}

.slide_7 {
  .box-with-text {
    &:before {
      content: 'Интерьер';
    }
  }
}

.slide_12 {
  .box-with-text {
    &:before {
      content: 'Lifestyle';
    }
  }
}

.accessory-caption {
  font-size: 5.7rem;
  font-weight: bold;
  line-height: 1;
  position: relative;
  top: 14%;
  opacity: 0;
  transform: translateX(-5%);
  transition: all .5s ease-in-out;
  letter-spacing: -.022em;
  /* transition-delay: .5s; */

  @media $handheld {
    font-size: 4.5rem;
  }

  @media $small-mobile {
    display: none;
  }

  .slide.inview & {
    opacity: 1;
    transform: translateX(0%);
  }
}

.accessory-description {
  opacity: 0;
  transition: opacity 1s ease;
  /* transition-delay: 1s; */
  transition-delay: .5s;
  position: relative;
  font-size: 1.8rem;
  font-weight: $thin;

  @media $handheld {
    font-size: 1.4rem;
  }

  @media $portrait-tablet {
    .section-group .slide & {
      bottom: 7%;
    }
  }

  @media $small-mobile {
    transition-delay: 0s;

    .section-group .slide & {
      position: absolute;
      top: auto; right: auto;
      z-index: 5;
      top: 60px; left: 10%; right: 10%;
      padding: 0;
      font-size: 1.1rem;

      br {
        display: none;
      }
    }
  }

  &:before {
    content: '';
    display: block;
    width: 43px; height: 4px;
    background: $red;
    position: absolute;
    left: 0; top: -30px;
    max-width: 0;
    transition: max-width .5s ease;
    /* transition-delay: 1.5s; */
    transition-delay: 1s;

    @media $small-mobile {
      top: -10px;
      transition-delay: .5s;
    }
  }

  .slide.inview & {
    opacity: 1;

    &:before {
      max-width: 43px;
    }
  }

  p + p {
    margin-top: 1em;
  }
}

.accessory-quote {
  opacity: 0;
  transition: opacity 1s ease;
  /* transition-delay: 1.5s; */
  transition-delay: 1s;
  position: relative;
  font-size: 1.8rem;
  font-weight: $thin;
  font-style: italic;
  padding: 12px 33px 33px 56px;

  @media $handheld {
    font-size: 1.4rem;
  }

  @media $portrait-tablet {
    .section-group .slide & {
      right: 4%; bottom: calc(7% - 33px);
    }
  }

  @media $small-mobile {
    transition-delay: .5s;

    .section-group .slide & {
      position: absolute;
      top: auto; right: auto;
      z-index: 5;
      bottom: 30px; left: 10%; right: 10%;
      padding: 0;
      font-size: 1.1rem;

      br {
        display: none;
      }

      &:after {
        display: none;
      }

      &:before {
        font-size: 0;
        background: url(/i/quote-bubble.png) no-repeat 50%;
        width: 60px; height: 33px;
        left: -20px; top: -20px;
      }
    }
  }

  &:before, &:after {
    color: $red;
    position: absolute;
    font-size: 9.1rem;
    font-weight: bold;
    font-style: italic;
    line-height: .8;
    opacity: 0;
    transition: opacity 1s ease;

    @media $handheld {
      font-size: 6rem;
    }
  }

  &:before {
    content: '“';
    left: -10px; top: 10px;
    /* transition-delay: 2s; */
    transition-delay: 1.5s;

    @media $small-mobile {
      transition-delay: 1s;
    }
  }

  &:after {
    content: '”';
    right: -15px; bottom: -35px;
    /* transition-delay: 2.5s; */
    transition-delay: 2s;

    @media $small-mobile {
      transition-delay: 1.5s;
    }
  }

  .slide.inview & {
    &, &:before, &:after {
      opacity: 1;
    }
  }
}

.slide_3 {
  background: url(/i/slide-3.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-3-m.jpg', '/i/slide-3-m@2x.jpg', '/i/slide-3-m-hd@2x.jpg');

  .glitch {
    display: block;
    width: 100%; padding-bottom: 50%;
    background: url(/i/slide-3-glitch.png) no-repeat 0 0 / contain;
    position: absolute;
    left: 0; bottom: 0;
    transform: scale(.7);
    opacity: 0;
    transition: all 1.5s ease;
    transition-delay: .35s;
  }

  &.inview .glitch {
    transform: scale(1);
    opacity: 1;
  }

  .accessory-description {
    position: absolute;
    left: 0; bottom: calc(7% + 33px);

    @media $portrait-tablet {
      max-width: 40%;

      br {
        display: none;
      }
    }
  }

  .accessory-quote {
    position: absolute;
    right: 7%; bottom: 7%;

    @media $portrait-tablet {
      max-width: 30%;
    }
  }
}

.slide_4 {
  background: url(/i/slide-4.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-4-m.jpg', '/i/slide-4-m@2x.jpg', '/i/slide-4-m-hd@2x.jpg');

  .accessory-description {
    position: absolute;
    left: 0; bottom: 15%;

    @media $portrait-tablet {
      max-width: 40%;
    }
  }

  .accessory-quote {
    position: absolute;
    right: 7%; bottom: 8%;

    &:after {
      bottom: -15px;
    }

    @media $portrait-tablet {
      max-width: 30%;
    }
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    opacity: 0;
    transition: all 5s ease-in-out;
  }

  .glitch_1 {
    background: url(/i/slide-4-glitch-1.png) no-repeat 50% / cover;
    transform: scale(.85);
  }

  .glitch_2 {
    background: url(/i/slide-4-glitch-2.png) no-repeat 50% / cover;
    transition-delay: .5s;
    transition-duration: 4s;
    transform: scale(1.2);
  }

  .glitch_3 {
    background: url(/i/slide-4-glitch-3.png) no-repeat 50% / cover;
    transition-delay: 1s;
    transform: scale(1.1);
  }

  &.inview .glitch {
    opacity: 1;
    transform: scale(1);
  }
}

.slide_5 {
  @media $not-small-handheld {
    display: flex;
  }

  @media $small-handheld {
    height: auto;
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    opacity: 0;
    transition: all 3s ease-in-out;
  }

  .glitch_1 {
    background: url(/i/slide-5-glitch-1.png) no-repeat 50% / cover;
    transform: translateX(-10%);
  }

  .glitch_2 {
    background: url(/i/slide-5-glitch-2.png) no-repeat 50% / cover;
    transform: translateY(-5%);
  }

  .slide--half {
    overflow: hidden;
    position: relative;

    @media $not-small-handheld {
      flex: 1;
      display: flex;

      .wrapper {
        flex: 1;
      }
    }

    @media $small-handheld {
      @extend .slide;
    }
  }

  .slide--half_left {
    background: url(/i/slide-5-1.jpg) no-repeat 50% / cover;
    justify-content: flex-end;

    mobile-bg('/i/slide-5-1-m.jpg', '/i/slide-5-1-m@2x.jpg', '/i/slide-5-1-m-hd@2x.jpg');

    @media $not-small-mobile {
      .accessory-quote {
        position: absolute;
        left: 0; bottom: 5%;

        &:after {
          bottom: -15px;
        }

        @media $portrait-tablet {
          right: auto;
        }
      }

      .accessory-description {
        position: absolute;
        left: 56px; bottom: calc(5% + 8em);
      }
    }

    @media only screen and (min-width: $wrapper-width + 20px) {
      .wrapper {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .slide--half_right {
    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: rgba($black, .25);
      position: absolute;
      left: 0; top: 0;
      z-index: 1;
    }

    .accessory-caption {
      position: absolute;
      top: auto; bottom: 9%;
      z-index: 10;
    }

    @media $small-handheld {
      display: none;
    }
  }

  .slide--video {
    height: 101%;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }

  &.inview {
    .glitch {
      opacity: 1;
    }

    .glitch_1 {
      transform: translateX(0%);
    }

    .glitch_2 {
      transform: translateY(0%);
    }
  }
}

.slide_6 {
  @media $not-small-mobile {
    height: 660px;
  }

  background: url(/i/slide-6.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-6-m.jpg', '/i/slide-6-m@2x.jpg', '/i/slide-6-m-hd@2x.jpg');

  .accessory-description {
    position: absolute;
    left: 0; bottom: 24%;
  }

  .accessory-quote {
    position: absolute;
    right: 7%; bottom: 18%;

    &:after {
      bottom: -15px;
    }

    @media $portrait-tablet {
      max-width: 30%;
    }
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    opacity: 0;
    transition: all 5s ease-in-out;
    background: url(/i/slide-6-glitch.png) no-repeat 50% / cover;
    transform: scale(.85);
  }

  .lights {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;

    @media $mobile {
      display: none !important;
      background: none !important;
    }
  }

  .lights_1 {
    background: url(/i/slide-6-lights-1.png) no-repeat 50%;
    animation: lights-1 infinite 5s ease-in-out;
  }

  .lights_2 {
    background: url(/i/slide-6-lights-2.png) no-repeat 50%;
    animation: lights-2 infinite 3.5s ease-in-out;
  }

  .lights_3 {
    background: url(/i/slide-6-lights-3.png) no-repeat 50%;
    animation: lights-3 infinite 5s ease-in-out;
  }

  &.inview .glitch {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes lights-1 {
  0%   { opacity: .25; }
  50%  { opacity: 1; }
  100% { opacity: .25; }
}

@keyframes lights-2 {
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes lights-3 {
  0%   { opacity: .35; }
  50%  { opacity: 1; }
  100% { opacity: .35; }
}

.slide_8 {
  @media $not-small-mobile {
    height: 735px;
  }

  background: url(/i/slide-8.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-8-m.jpg', '/i/slide-8-m@2x.jpg', '/i/slide-8-m-hd@2x.jpg');

  .accessory-description {
    position: absolute;
    left: 0; bottom: 15%;
  }

  .accessory-quote {
    position: absolute;
    right: 7%; bottom: 10%;

    /* &:after {
      bottom: -15px;
    } */
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    opacity: 0;
    transition: all 5s ease-in-out;
  }

  .glitch_1 {
    background: url(/i/slide-8-glitch-1.png) no-repeat 50% / cover;
    transform: scale(.85);
  }

  .glitch_2 {
    background: url(/i/slide-8-glitch-2.png) no-repeat 50% / cover;
    transition-delay: .5s;
    transition-duration: 4s;
    transform: scale(1.2);
  }

  &.inview .glitch {
    opacity: 1;
    transform: scale(1);
  }
}

.slide_9 {
  @media $not-small-mobile {
    height: 664px;
  }

  background: url(/i/slide-9.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-9-m.jpg', '/i/slide-9-m@2x.jpg', '/i/slide-9-m-hd@2x.jpg');

  .accessory-description {
    position: absolute;
    left: 0; bottom: 10%;
  }

  .accessory-quote {
    position: absolute;
    right: 7%; bottom: 6%;

    /* &:after {
      bottom: -15px;
    } */

    @media $portrait-tablet {
      max-width: 30%;

      br {
        display: none;
      }
    }
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
  }

  .glitch_1 {
    background: url(/i/slide-9-glitch-1.png) no-repeat 50% / cover;
    transform: scale(.85);
    left: 0; bottom: 0;
    transform: scale(.7);
    opacity: 0;
    transition: all 1.5s ease;
    transition-delay: .35s;
  }

  .glitch_2 {
    background: url(/i/slide-9-glitch-2.png) no-repeat 50% / cover;
    transition-delay: .5s;
    transition-duration: 4s;
    transform: scale(1.2);
    left: 0; top: 0;
    opacity: 0;
    transition: all 5s ease-in-out;
  }

  &.inview .glitch {
    opacity: 1;
    transform: scale(1);
  }
}

.slide_10 {
  background: url(/i/slide-10.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-10-m.jpg', '/i/slide-10-m@2x.jpg', '/i/slide-10-m-hd@2x.jpg');

  .accessory-description {
    position: absolute;
    left: 0; bottom: 15%;
  }

  .accessory-quote {
    position: absolute;
    right: 7%; bottom: 8%;

    @media $portrait-tablet {
      max-width: 30%;

      br {
        display: none;
      }
    }
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    opacity: 0;
    transition: all 5s ease-in-out;
  }

  .glitch_1 {
    background: url(/i/slide-10-glitch-1.png) no-repeat 50% / cover;
    transform: scale(.85);
  }

  .glitch_2 {
    background: url(/i/slide-10-glitch-2.png) no-repeat 50% / cover;
    transition-delay: .5s;
    transition-duration: 4s;
    transform: scale(1.2);
  }

  &.inview .glitch {
    opacity: 1;
    transform: scale(1);
  }
}

.slide_11 {
  background: url(/i/slide-11.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-11-m.jpg', '/i/slide-11-m@2x.jpg', '/i/slide-11-m-hd@2x.jpg');

  .accessory-description {
    position: absolute;
    left: 0; bottom: 10%;

    @media $portrait-tablet {
      max-width: 40%;

      br {
        display: none;
      }
    }
  }

  .accessory-quote {
    position: absolute;
    right: 7%; bottom: 6%;

    @media $portrait-tablet {
      max-width: 30%;

      br {
        display: none;
      }
    }
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    background: url(/i/slide-11-glitch.png) no-repeat 50% / cover;
    opacity: 0;
    transform: translateX(5%);
    transition: all 5s ease-in-out;
  }

  .lights {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    background: url(/i/slide-11-light.png) no-repeat 50% / cover;
    opacity: 0;
    animation: lights-3 infinite 7s ease-in-out;

    @media $mobile {
      display: none !important;
      background: none !important;
    }
  }

  &.inview .glitch {
    opacity: 1;
    transform: translateX(0%);
  }
}

.slide_13 {
  background: url(/i/slide-13.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-13-m.jpg', '/i/slide-13-m@2x.jpg', '/i/slide-13-m-hd@2x.jpg');

  transition-delay: 0s;

  .accessory-description {
    @media $not-small-mobile {
      position: absolute;
      left: 0; top: 50%;
      transform: translateY(-50%);
    }

    @media $small-mobile {
      .section-group & {
        left: 30px;
      }
    }

    &:before {
      display: none;
    }

    .group + .group {
      margin-top: 2.5em;
    }

    .group {
      opacity: 0;
      transform: translateX(-10%);
      transition: all 1s ease-in-out;
    }

    .group_1 {
      transition-delay: 0s;
    }

    .group_2 {
      transition-delay: .25s;
    }

    .group_3 {
      transition-delay: .5s;
    }
  }

  .accessory-subcaption {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: .35em;

    @media $handheld {
      font-size: 2.5rem;
    }

    @media $small-mobile {
      font-size: 2rem;
    }
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    opacity: 0;
  }

  .glitch_1 {
    background: url(/i/slide-13-glitch-1.png) no-repeat 50% / cover;
    transform: translateX(-5%);
    transition: all 3s ease-in-out;
  }

  .glitch_2 {
    background: url(/i/slide-13-glitch-2.png) no-repeat 50% / cover;
    transform: translate(-5%, -2%);
    transition: all 4s ease-in-out;
  }

  .glitch_3 {
    background: url(/i/slide-13-glitch-3.png) no-repeat 50% / cover;
    transform: scale(1.35);
    transition: all 2s ease-in-out;
  }

  &.inview {
    .glitch {
      opacity: 1;
      transform: translate(0%, 0%) scale(1);
    }

    .accessory-description .group {
      transform: translateX(0%);
      opacity: 1;
    }
  }
}

.slide_14 {
  @media $not-small-mobile {
    height: 789px;
  }

  background: url(/i/slide-14.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-14-m.jpg', '/i/slide-14-m@2x.jpg', '/i/slide-14-m-hd@2x.jpg');

  .accessory-description {
    position: absolute;
    left: 0; bottom: 24%;
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    background: url(/i/slide-14-glitch.png) no-repeat 50% / cover;
    opacity: 0;
    transform: scale(.85);
    transition: all 3s ease-in-out;
  }

  &.inview .glitch {
    opacity: 1;
    transform: scale(1);
  }
}

.slide_15 {
  height: 'calc(100vh - %s)' % $bottom-menu-height;
  background: url(/i/slide-15.jpg) no-repeat 50%;
  overflow: hidden;

  mobile-bg('/i/slide-15-m.jpg', '/i/slide-15-m@2x.jpg', '/i/slide-15-m-hd@2x.jpg');

  .accessory-description {
    position: absolute;
    left: 0; bottom: 6%;
  }

  .glitch {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    opacity: 0;
  }

  .glitch_1 {
    background: url(/i/slide-15-glitch-1.png) no-repeat 50% / cover;
    transform: scale(1.1);
    transition: all 4s ease-in-out;
  }

  .glitch_2 {
    background: url(/i/slide-15-glitch-2.png) no-repeat 50% / cover;
    transform: scale(.8);
    transition: all 3s ease-in-out;
  }

  &.inview .glitch {
    opacity: 1;
    transform: scale(1);
  }

  @media $small-mobile {
    .accessory-caption {
      display: block;
      font-size: 3rem;
    }

    .bmw-shop-link {
      margin-top: 10px;
    }
  }
}

.bmw-shop-link {
  @extend .btn;
  background: $blue;
  height: 45px;
  line-height: 48px;
  width: 160px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media $small-mobile {
  .slide_3,
  .slide_4,
  .slide_5,
  .slide_6,
  .slide_8,
  .slide_9,
  .slide_10,
  .slide_11,
  .slide_13,
  .slide_14,
  .slide_15 {
    &:before {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: radial-gradient(ellipse at top right, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
      position: absolute;
      left: 0; top: 0;
      z-index: 1;
    }
  }
}

@media $mobile {
  .glitch {
    display: none !important;
    background: none !important;
  }
}
