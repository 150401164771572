
/*   -=========== Menus ===========-   */

// Base

.menu {
  ul, ol, li {
    margin: 0;
    list-style: none;
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  .active > a {
    cursor: default;
  }

  li, a {
    user-select: none;
  }

  a:not([href]) {
    cursor: default;
  }
}

.inline-menu {
  li {
    display: inline-block;
  }
}

.horizontal-menu {
  ul {
    clearfix();
  }

  li {
    float: left;
  }
}

.vertical-menu {
  li {
    display: block;
  }
}

.justify-menu {
  > ul, > ol {
    line-height: 0;
    text-align: justify;

    &:after {
      width: 100%;
      height: 0;
      content: "";
      font-size: 0;
      line-height: 0;
      display: inline-block;
      visibility: hidden;
      overflow: hidden;
    }

    > li {
      display: inline-block;
    }
  }
}

.table-menu {
  ul {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  li {
    display: table-cell;
  }
}


// Bottom menu

.bottom-menu {
  width: 100%;
  position: fixed;
  bottom: 0; left: 0;
  z-index: 100;
  background: $black;

  @media $desktop {
    transform: translateY(100%);
    transition: all 1s ease;
    transition-delay: 3s;
  }

  body.is-ready & {
    transform: translateY(0%);
  }

  .wrapper {
    display: flex;
    line-height: $bottom-menu-height;
    position: relative;
  }

  .menu-section {
    min-width: 80px;
    color: $blue;
    font-size: 1.2rem;
    font-weight: bold;

    @media $not-small-mobile {
      padding-left: 30px;
    }

    li {
      list-style: none;
    }

    li:not(.is-active) {
      display: none;
    }
  }

  .dropdown-anchor {
    color: $blue;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;

    @media $not-small-mobile {
      margin-left: 30px;
      padding-right: 40px;
    }

    @media $small-mobile {
      padding-right: 30px;

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.2;
        margin-top: 3px;
      }
    }

    .icon {
      position: absolute;
      right: 7px; top: 50%;
      transform: translateY(-50%);
      width: 14px; height: 8px;
      transition: all .5s ease;

      &:before, &:after {
        content: '';
        display: block;
        width: 10px; height: 2px;
        background: $white;
        position: absolute;
        top: 0;
      }

      &:before {
        right: 50%;
        transform: rotate(-45deg);
        transform-origin: right top;
      }

      &:after {
        left: 50%;
        transform: rotate(45deg);
        transform-origin: left top;
      }
    }

    &.is-active {
      .icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    box-sizing: border-box;
    position: absolute;
    left: 0; bottom: 100%;
    max-height: 0;
    opacity: 0;
    transition: all .5s ease;
    padding-left: 30px;
    padding-right: 50px;
    overflow: hidden;
    background: rgba($black, .9);
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.2;

    @media $small-mobile {
      padding-left: 10px;
      padding-right: 20px;
      margin-left: -10px;
      margin-right: -10px;
    }

    &.is-active {
      opacity: 1;
      max-height: 450px;
      padding-top: 40px;
      padding-bottom: 20px;
    }

    > li {
      display: flex;
      align-items: flex-start;

      + li {
        margin-top: 2.5em;
      }

      li + li {
        margin-top: 1em;
      }

      a {
        color: $blue;
        transition: color .25s ease;

        &:hover {
          color: $white;
        }
      }
    }

    .menu--caption {
      width: 110px;
      flex-shrink: 0;
      color: #808284;

      @media $small-mobile {
        width: 80px;
      }
    }
  }
}
