@font-face {
  font-family: 'bmw';
  src: url('/i/fonts/bmwtypbo-webfont.woff2') format('woff2'),
       url('/i/fonts/bmwtypbo-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'bmw';
  src: url('/i/fonts/bmwtypli-webfont.woff2') format('woff2'),
       url('/i/fonts/bmwtypli-webfont.woff') format('woff');
  font-weight: $light;
  font-style: normal;
}

@font-face {
  font-family: 'bmw';
  src: url('/i/fonts/bmwtypth-webfont.woff2') format('woff2'),
       url('/i/fonts/bmwtypth-webfont.woff') format('woff');
  font-weight: $thin;
  font-style: normal;
}

/*
@font-face {
	font-family:'bmw_bold';
	src: url("/i/fonts/BMWTypeWebBoldAll.20161018.woff2") format("woff2"),
			 url("/i/fonts/BMWTypeWebBoldAll.20161018.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family:'bmw';
	src: url("/i/fonts/BMWTypeWebLightAll.20161018.woff2") format("woff2"),
			 url("/i/fonts/BMWTypeWebLightAll.20161018.woff") format("woff");
	font-weight: $light;
}

@font-face {
	font-family:'bmw';
	src: url("/i/fonts/BMWTypeWebThinAll.20171026.woff2") format("woff2"),
			 url("/i/fonts/BMWTypeWebThinAll.20171026.woff") format("woff");
	font-weight: $thin;
}
 */
